import * as React from "react"
import Layout from "../components/layout";
import {Helmet} from "react-helmet";
import "../styles/404.css"
import guns from "../images/guns-pistol.svg"

const NotFoundPage = () => {
  return (
      <Layout>
          <Helmet title="404 - Page not found | Mafiaverse">
              <html lang="en" />
              <meta name="description" content="Page not found" />
          </Helmet>
          <header className="hero is-fullheight not-found-container">
              <div className="hero-body">
                  <div className="container has-text-centered">
                      <h1 className="title is-underlined head-404">404</h1>
                      <img src={guns.toString()} height="420" width="420" alt="404 Image" />
                  </div>
              </div>
          </header>
      </Layout>
  )
}

export default NotFoundPage
